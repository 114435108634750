@tailwind base;
@tailwind components;
@tailwind utilities;

.otp-form {
  > div {
    justify-content: end;
    direction: ltr;

    span {
      @apply opacity-25;
    }
  }

  div {
    span {
      @apply px-2;
    }
  }
}

.otp-form > div input {
  @apply bg-white border border-neutral-300 text-neutral-800 text-3xl h-14 shadow-none w-12 rounded-xl #{!important};
  -moz-font-feature-settings: "ss02";
  -webkit-font-feature-settings: "ss02";
  font-feature-settings: "ss02";
  box-shadow: 0px 4px 2px rgba(113, 114, 125, 0.03) !important;
}

.button-primary {
  background: linear-gradient(180deg, #7473ff 0%, #b59fff 100%);
}

.posters {
  -webkit-animation: slide 60s linear infinite;
  animation: slide 60s linear infinite;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform-style: preserve-3d;
  width: calc(800px * 3);
  height: 1000px;
  will-change: transform;
  &:after {
    content: "";
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) -3.94%,
      #f6f7ff 39.58%
    );
    position: absolute;
    bottom: 200px;
    height: 550px;
  }
}

@keyframes slide {
  to {
    transform: translate3d(calc(800px * -1), 0, 0);
  }
}

.apexcharts-toolbar {
  right: unset !important;
  left: 18px;
  top: -64px !important;

  .apexcharts-menu-icon {
    @apply flex items-center justify-center bg-white border p-5 rounded-lg;
    box-shadow: rgba(113, 114, 125, 0.03) 0px 4px 2px;

    img {
      position: absolute;
      @apply w-5 h-5 #{!important};
    }
  }
}

.apexcharts-inner {
  transform: scale(1.1) translateX(-25px);
  display: flex;
  justify-content: center;
}

.apexcharts-menu-open {
  @apply flex flex-col items-center justify-center bg-white border rounded-lg border-[#E6E6E6] #{!important};
  box-shadow: rgba(113, 114, 125, 0.03) 0px 4px 2px;
  right: unset !important;
  left: 10px !important;

  div {
    &:hover {
      @apply rounded-lg bg-[#F7F9FF] #{!important};
    }
  }
}

.apexcharts-theme-light.apexcharts-tooltip {
  background: rgba(255, 255, 255, 0.4) !important;
  backdrop-filter: blur(8px) !important;
  border: none !important;
  min-width: 120px;
  border-radius: 16px !important;
  padding: 2px 10px;

  .apexcharts-tooltip-marker {
    display: none !important;
  }

  .apexcharts-tooltip-title {
    background: none !important;
    border: none !important;
    font-size: 16px !important;
    padding-right: 0 !important;
    font-weight: 600;
    margin-bottom: 0 !important;
  }

  .apexcharts-tooltip-series-group {
    padding-right: 0 !important;
    @apply w-full grow pr-1 #{!important};
    text-align: right;
    justify-content: right;
  }
}

.apexcharts-marker {
  cursor: pointer !important;
}

.hide-scroll {
  &::-webkit-scrollbar {
    width: 0;
  }
}

@media (min-width: 1536px) {
  .mr-\[250px\] {
    .container {
      max-width: 1280px;
    }
  }
}

tr[role="row"] {
  th {
    &:first-child {
      @apply rounded-r-lg;
    }
    &:last-child {
      @apply rounded-l-lg;
    }
  }
  td {
    @apply border-gray-200;

    &:first-child {
      @apply rounded-r-lg;
    }

    &:last-child {
      @apply rounded-l-lg;
    }
  }
}

@layer base {
  select {
    background-position: left 0.45rem center !important;
    padding-left: 1.75rem !important;
    padding-right: 0.75rem !important;
    font-size: 15px;
  }
}
